import { Component, OnInit, Signal, computed, effect, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule, Form } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../auth.service';

import { AccountService } from '../../account.service';
import { CommonModule } from '@angular/common';

import { toSignal } from '@angular/core/rxjs-interop';
import { interval, map, Observable, of, scan, startWith } from 'rxjs';
import { TuiInputModule } from '@taiga-ui/legacy';
import { TuiButton, TuiError, TuiTextfield } from '@taiga-ui/core';
import { environment } from '../../../../environments/environment';
import { CustomValidators } from '../../../shared/extensions/CustomValidators';
import {TUI_VALIDATION_ERRORS, TuiFieldErrorPipe} from '@taiga-ui/kit';
import { tuiIsFalsy } from '@taiga-ui/cdk/utils/miscellaneous';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  standalone:true,
  imports:[CommonModule, TuiInputModule,TuiTextfield,TuiError, ReactiveFormsModule,TuiFieldErrorPipe,FormsModule ,TuiButton],
  providers: [ {
    provide: TUI_VALIDATION_ERRORS,
    useValue: {
        required: 'Required!',
        email: 'Enter a valid email',
        maxlength: ({requiredLength}: {requiredLength: string}) =>
            `Maximum length — ${requiredLength}`,
        minlength: ({requiredLength}: {requiredLength: string}) =>
            of(`Minimum length — ${requiredLength}`),
        min: interval(2000).pipe(
            scan(tuiIsFalsy, false),
            map((val) => (val ? 'Fix please' : 'Min number 3')),
            startWith('Min number 3'),
        ),
    },
},]
})
export class ResetPasswordComponent implements OnInit {

  private readonly router:Router = inject(Router);

  model!: Signal<passwordResetToken>;

  form: UntypedFormGroup;
  error: string | null = null;
version: string;
  constructor(private route: ActivatedRoute, private accountService: AccountService) {

    this.version = environment.version;

     this.form = new UntypedFormGroup({
      'password': new UntypedFormControl(null, [CustomValidators.passwordHasUpper, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.required]),
      'repeatPassword': new UntypedFormControl(null,CustomValidators.required),
      'token': new UntypedFormControl(null,CustomValidators.required),
      'email': new UntypedFormControl(null,CustomValidators.required),
    },{validators: [CustomValidators.passwordsMatch('password','repeatPassword')]});

    this.form.valueChanges.subscribe((changes) => {
      this.error = null;
    });







    // this.route.queryParams.subscribe((params) => {
    //   if (params['token']) {
    //      this.form.get('token').setValue(params['token']);
    //      this.form.get('token').markAsDirty();
    //   }
    //   if(params['email'])
    //   {
    //     this.form.get('email').setValue(params['email']);
    //     this.form.get('email').markAsDirty();
    //   }
    // });
  }

  ngOnInit(): void {

  }

  onSubmit(): void {

    if(this.form.invalid)
    {
      return;
    }


    this.accountService.resetPasswordWithToken(this.model()).subscribe({
      error: error => this.error = error,
      next: value =>  {
        this.router.navigate(['/login'])
    }
    })
  }



}



export class passwordResetToken {
  token?:string = ''
  email?:string = ''
  password?:string = ''
}
