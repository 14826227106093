import { Component, OnInit, Signal, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { AuthService } from '../../auth.service';
import { environment } from '../../../../environments/environment';
import { TuiInputModule } from '@taiga-ui/legacy';
import { TuiButton, TuiTextfield } from '@taiga-ui/core';



@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule, RouterModule, TuiInputModule, TuiTextfield, TuiButton],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  error: string | null = '';
  public version: string = '1.2.3-dev.20230904';

  private readonly authService:AuthService = inject(AuthService);



  constructor(private router:Router, private route:ActivatedRoute) {

    this.version = environment.version;

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required,Validators.email]),
      password: new UntypedFormControl(null, Validators.required),
    });

    this.loginForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });



  }

  onSubmit(): void {

    this.authService.login(this.loginForm.getRawValue()).subscribe({
      next: user => {
        this.router.navigate(['']);
      },
      error: error =>  {

      }
    });

  }


}
