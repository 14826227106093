<div class="flex items-center justify-center min-h-screen">
  <section class="w-full max-w-xl p-6">
    <header class="mb-4">
      <h1 class="text-2xl font-bold text-center">Reset Password</h1>
    </header>
    <main>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="space-y-4">

        <tui-input formControlName="password">
          Password
          <input tuiTextfieldLegacy type="password" />

        </tui-input>
        <tui-error
        formControlName="password"
        [error]="[] | tuiFieldError | async"
    />

        <tui-input formControlName="repeatPassword">
          Repeat Password
          <input tuiTextfieldLegacy type="password" />

        </tui-input>
        <tui-error
        formControlName="repeatPassword"
        [error]="[] | tuiFieldError | async"
    />
        <div class="actions">
          <button
            tuiButton
            appearance="primary"
            size="l"
            type="submit"
            [disabled]="!form.valid"
            class="w-full"
          >
            Reset Password
          </button>
        </div>
      </form>

    </main>
  </section>
</div>
