<div class="flex items-center justify-center min-h-screen">
  <section class="w-full max-w-xl p-6">
    <header class="mb-4">
      <h1 class="text-2xl font-bold text-center">BRA</h1>
    </header>
    <main>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="space-y-4">
        <tui-input formControlName="email">
          Email
          <input tuiTextfieldLegacy type="email" />
        </tui-input>

        <tui-input formControlName="password">
          Password
          <input tuiTextfieldLegacy type="password" />
        </tui-input>

        <div class="actions">
          <button
            tuiButton
            appearance="primary"
            size="l"
            type="submit"
            [disabled]="!loginForm.valid"
            class="w-full"
          >
            Login
          </button>
        </div>
      </form>
    </main>
    <footer
      *ngIf="version"
      class="mt-4 flex justify-between items-center text-sm"
    >
      <a class="text-blue-500 hover:underline" routerLink="/forgot-password"
        >Forgot Password?</a
      >
      <span class="text-gray-500">{{ version }}</span>
    </footer>
  </section>
</div>
