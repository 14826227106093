<div class="flex items-center justify-center min-h-screen">
  <section class="w-full max-w-xl p-6">
    <header class="mb-4">
      <h1 class="text-2xl font-bold text-center">Forgot Password</h1>
    </header>
    <main>
      @if(requestSent)
      {
        <div class="text-center text-gray-700">
          An email has been sent to you with instructions on how to reset your password.
        </div>
    }
      @else {

          <form
            [formGroup]="forgotPasswordForm"
            (ngSubmit)="onSubmit()"
            class="space-y-4"
          >
            <tui-input
              formControlName="email"
              label="Email"
              placeholder="example@example.com.au"
              [tuiTextfieldCleaner]="true"
            >Email</tui-input>

            <div class="actions">
              <button
                tuiButton
                appearance="primary"
                size="l"
                type="submit"
                [disabled]="!forgotPasswordForm.valid"
                class="w-full"
              >
                Reset Password
              </button>
            </div>
          </form>
      }
    </main>
    <footer
      class="mt-4 flex justify-between items-center text-sm"
    >
      <a
        class="text-blue-500 hover:underline flex items-center"
        routerLink="/login"
      >
        <span class="mr-1">
          <i class="fa fa-arrow-left"></i>
        </span>
        Back to login
      </a>
      <span class="text-gray-500">{{ version }}</span>
    </footer>
  </section>
</div>
