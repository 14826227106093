import { NG_EVENT_PLUGINS } from "@taiga-ui/event-plugins";
import { provideAnimations } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from "@angular/common/http";
import { NgxPermissionsModule } from "ngx-permissions";
import { AccountService } from "./Auth/account.service";
import { authInterceptor } from "./shared/interceptors/auth.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [ provideAnimations(), provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideHttpClient(withInterceptors([authInterceptor])) ,NG_EVENT_PLUGINS,
    {
      provide: APP_INITIALIZER,
      useFactory: (accountService: AccountService) => () => Promise.resolve(),
      multi: true,
      deps: [AccountService],
    },
    importProvidersFrom(NgxPermissionsModule.forRoot()),
  ]
};
