// auth.interceptor.ts
import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  filter,
  first,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { AuthService } from '../../Auth/auth.service';

const TOKEN_HEADER_KEY = 'Authorization';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);

  const exclusionList: string[] = ['/auth/login', '/auth/token/refresh'];

  const isInExclusionList = exclusionList.some((url) => req.url.includes(url));

  if (isInExclusionList) {
    return next(
      req.clone({
        withCredentials: true,
      })
    );
  }

  const authHeader = req.headers.get('Authorization');
  if (authHeader) {
    const clonedReq = req.clone({
      withCredentials: true,
      headers: req.headers,
    });

    return next(clonedReq);
  }

  const authReq = req;
  return next(authReq).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        const httpErrorCode: number = error.status;
        switch (httpErrorCode) {
          case 404:
            return throwError(() => new Error(`${error}`));
          case 401:
            return handle401Error(authReq, next, authService);
          default:
            return throwError(() => new Error(`${error}`));
        }
      } else {
        return throwError(() => new Error(`${error}`));
      }
    })
  );
};

const handle401Error = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
  authService: AuthService
): Observable<HttpEvent<any>> => {
  return authService.isRefreshingToken$.pipe(
    first(),
    switchMap((refreshStatus) => {
      switch (refreshStatus.status) {
        case 'SUCCESSFUL':
          return handleRequestWithToken(request, next, authService);
        case 'FAILED':
          return throwError(() => new Error('Token refresh failed'));
        case 'NOT STARTED':
          return authService.refreshToken().pipe(
            switchMap(() => handleRequestWithToken(request, next, authService))
          );
        case 'IN PROGRESS':
          return authService.isRefreshingToken$.pipe(
            filter(
              ({ status }) =>
                status === 'SUCCESSFUL' || status === 'FAILED'
            ),
            take(1),
            switchMap((updatedStatus) => {
              if (updatedStatus.status === 'SUCCESSFUL') {
                return handleRequestWithToken(request, next, authService);
              } else {
                authService.logout();
                return throwError(
                  () => new Error('Token refresh failed after waiting')
                );
              }
            })
          );
        default:
          return throwError(
            () => new Error('Unexpected refresh token status')
          );
      }
    })
  );
};

const handleRequestWithToken = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
  authService: AuthService
): Observable<HttpEvent<any>> => {
  return next(addTokenInHeader(request));
};

const addTokenInHeader = (request: HttpRequest<any>): HttpRequest<any> => {
  return request.clone({
    // If you need to set the Authorization header, uncomment and modify the line below:
    // setHeaders: { Authorization: 'Bearer ' + authService.getToken() },
    withCredentials: true,
  });
};
