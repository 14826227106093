import { Routes } from '@angular/router';
import { ActionPortalService } from './admin/features/action-portal/services/action-portal.service';
import { LoginComponent } from './Auth/components/login/login.component';
import { AuthGuard } from './Auth/guards/auth.guard';
import { AdminGuard } from './Auth/guards/role.guard';
import { ForgotPasswordComponent } from './Auth/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './Auth/components/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then(c => c.routes),
    providers:[ActionPortalService],
    canActivate:[AuthGuard,AdminGuard]
  },
  {
    path: 'login',
    component:LoginComponent
  },
  {
    path: 'forgot-password',
    component:ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component:ResetPasswordComponent
  },
  {
    path: '',
    loadChildren: () => import('./core/core.routes').then(r => r.routes),
    providers:[ActionPortalService],canActivate:[AuthGuard],
  }
];
