import { NgIf } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TuiValidationError } from '@taiga-ui/cdk/classes';
import { tuiIsString } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiHeightCollapse, tuiFadeIn } from '@taiga-ui/core/animations';
import { TUI_ANIMATIONS_SPEED, TUI_DEFAULT_ERROR_MESSAGE } from '@taiga-ui/core/tokens';
import { tuiToAnimationOptions } from '@taiga-ui/core/utils';
import { PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
const _c0 = () => ({});
function TuiError_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
function TuiError_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, TuiError_div_0_ng_container_1_Template, 2, 1, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@tuiFadeIn", ctx_r1.options)("@tuiHeightCollapse", ctx_r1.options);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.error.message || ctx_r1.default())("polymorpheusOutletContext", ctx_r1.error.context || i0.ɵɵpureFunction0(4, _c0));
  }
}
class TuiError {
  constructor() {
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.error = null;
    this.visible = true;
    this.default = toSignal(inject(TUI_DEFAULT_ERROR_MESSAGE));
  }
  set errorSetter(error) {
    this.error = tuiIsString(error) ? new TuiValidationError(error) : error;
  }
  onAnimation(visible) {
    this.visible = visible;
  }
  static {
    this.ɵfac = function TuiError_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiError)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiError,
      selectors: [["tui-error"]],
      hostBindings: function TuiError_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("animationcancel.self", function TuiError_animationcancel_self_HostBindingHandler() {
            return ctx.onAnimation(false);
          })("animationstart.self", function TuiError_animationstart_self_HostBindingHandler() {
            return ctx.onAnimation(true);
          });
        }
      },
      inputs: {
        errorSetter: [0, "error", "errorSetter"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [["automation-id", "tui-error__text", "class", "t-message-text", 4, "ngIf"], ["automation-id", "tui-error__text", 1, "t-message-text"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
      template: function TuiError_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiError_div_0_Template, 2, 5, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.error && ctx.visible);
        }
      },
      dependencies: [NgIf, PolymorpheusOutlet],
      styles: ["[_nghost-%COMP%]{display:block;font:var(--tui-font-text-s);color:var(--tui-text-negative);word-wrap:break-word;animation:tuiPresent 1s infinite}.t-message-text[_ngcontent-%COMP%]{white-space:pre-line}.t-message-text[_ngcontent-%COMP%]:before{content:\"\";line-height:1.5rem;vertical-align:bottom}"],
      data: {
        animation: [tuiHeightCollapse, tuiFadeIn]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiError, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-error',
      imports: [NgIf, PolymorpheusOutlet],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiHeightCollapse, tuiFadeIn],
      host: {
        '(animationcancel.self)': 'onAnimation(false)',
        '(animationstart.self)': 'onAnimation(true)'
      },
      template: "<div\n    *ngIf=\"error && visible\"\n    automation-id=\"tui-error__text\"\n    class=\"t-message-text\"\n    [@tuiFadeIn]=\"options\"\n    [@tuiHeightCollapse]=\"options\"\n>\n    <ng-container *polymorpheusOutlet=\"error.message || default() as text; context: error.context || {}\">\n        {{ text }}\n    </ng-container>\n</div>\n",
      styles: [":host{display:block;font:var(--tui-font-text-s);color:var(--tui-text-negative);word-wrap:break-word;animation:tuiPresent 1s infinite}.t-message-text{white-space:pre-line}.t-message-text:before{content:\"\";line-height:1.5rem;vertical-align:bottom}\n"]
    }]
  }], null, {
    errorSetter: [{
      type: Input,
      args: ['error']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiError };
