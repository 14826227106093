import { TuiRoot } from "@taiga-ui/core";
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faHome, faUser, faCoffee, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TuiRoot],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'app';

  private readonly library: FaIconLibrary = inject(FaIconLibrary);

  constructor() {
    this.library.addIcons(faHome, faUser, faCoffee, faChevronRight, faXmark);
  }
}
